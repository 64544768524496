@import '../../styles/variables';

.field {
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
}

.warning {
  color: $errorRed;
  font-weight: bold;
  line-height: 1;
  padding: 0.5rem 0;
  margin-bottom: 0.2rem;
}

.invalid {
  border-left: 0.3rem solid $errorRed;
  padding-left: 1rem;
}

.invalidInput {
  border-color: $errorRed !important;
  box-shadow: inset 0 0 0 0.1rem $errorRed !important;
  color: $errorRed;
}
