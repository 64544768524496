@import './variables';

:focus {
  outline: $primaryColor solid 1px;
}

[contenteditable] {
    -webkit-user-select: text;
    user-select: text;
}

html {
  font-size: 62.5%;
  font-family: 'Work Sans', sans-serif;
}

body {
  background: #fff;
  font-size: 1.6em;
  overflow-x: hidden;
  * {
    font-family: 'Work Sans', sans-serif;
    font-size: 1.4rem;
    box-sizing: border-box;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
}

h1,h2,h3,h4,h5,h6 {
  margin-bottom: 1em;
  margin-top: 0;
  font-weight: 500;
  color: #333;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2.3rem;
}

h3 {
  font-size: 2.1rem;
}

h4 {
  font-size: 1.9rem;
}

h5 {
  font-size: 1.7rem;
}

h6 {
  font-size: 1.5rem;
}

p {
  margin-top: 0;
  max-width: 70rem;
  font-size: 2.4rem;
  font-weight: 100;
  line-height: 1.5;
  letter-spacing: 0.02em;
  margin: 0 auto 2rem;
}

::placeholder {
  color: $grey;
}
