.root {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.full {
  flex-basis: 100%;
  > * {
    width: 100%;
  }
}

.half {
  flex-basis: calc(50% - 1rem);
  > * {
    width: 100%;
  }
}
