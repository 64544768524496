@import '../../styles/variables';

.root {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url('/images/circuit_board.jpg');
  background-size: cover;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 8rem;
  width: 90%;
  max-width: 50rem;
  height: 100%;
  input {
    border: 0 !important;
    border-bottom: 0.1rem solid $grey !important;
    padding-left: 2.25rem;
    box-shadow: none !important;
    outline: 0 !important;
  }
  svg {
    left: 0;
  }
  button {
    width: auto !important;
    display: inline-block;
  }
}

.logo {
  margin-bottom: 25vh;
  img {
    width: 6rem;
  }
}

.heading {
  font-weight: normal;
}

.badge {
  opacity: 0.5;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  width: 2rem;
}
