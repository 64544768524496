.root {
}

.content {
  padding: 0 2rem 2rem;
  @media (min-width: 768px) {
    padding: 0;
    padding-left: 8.5rem;
    padding-bottom: 2rem;
  }
}
