
@import '../../styles/variables';

.root {
  background: #fff;
  color: $darkGrey;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 8.5rem;
  z-index: 11;
  display: flex;
  flex-direction: column;
  box-shadow: 0.5rem 0 2rem rgba(0,0,0,0.25)
}


.logo {
  display: block;
  // border-radius: 50%;
  // background: $primaryColor;
  padding: 1rem;
  box-sizing: border-box;
  text-align: center;
  width: calc(100% - 2.5rem);
  margin: 1rem auto;
}

.links {
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.link {
  display: block;
  text-align: center;
  padding: 1.2rem 3rem;
  font-size: 1.4rem;
  letter-spacing: 0.02em;
  margin: 0;
  position: relative;
  cursor: pointer;
  & > svg {
	  font-size: 1.8rem;
	  display: inline-block;
	  transform: translateY(15%);
	}
	& > span {
		position: absolute;
	  display: none;
	  top: 0;
	  left: 100%;
    height: 100%;
    margin: 0;
    line-height: 1.5;
	  padding: 1.2rem 3rem;
	  color: #fff;
	  background: rgba(0,0,0,0.95);
  }
  &:last-child {
    margin-top: auto;
    justify-self: flex-end;
  }
  &:hover {
	background: $lightGrey;
	> span {
    display: block;
    white-space: nowrap;
	}
  }
}

.activeLink li{
  box-shadow: inset 0.3rem 0 $primaryColor;
}
