@import '../../styles/variables';

.root {
  margin-bottom: 3rem;
  background: #fff;
  box-shadow: 0 0.2rem 1rem rgba(0,0,0,0.2);
  padding: 1.5rem 2rem;
  color: #333;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.4rem;
}

.buildStatus {
  font-size: inherit;
  margin: 0;
  line-height: 1;
  font-weight: 600;
}

.spinner {
  width: 1em;
  height: 1em;
  stroke: $primaryColor;
  fill: $primaryColor;
  margin-right: 1rem;
}

.errorMessage {
  font-size: 1.2rem;
  margin:0 1rem;
  line-height: 1.4rem;
}

.error {
  background: $errorRed;
  color: #fff;
}

.success {
  background: $successGreen;
  color: #fff;
}
