@import '../../styles/variables';

.root {
  display: flex;
  padding: 0 3rem;
  margin-bottom: 4rem;
}

.title {
  margin: 0;
  font-size: 2.5rem;
  color: $primaryColor;
  flex: 1 1 auto;
  position: relative;
  &:before {
    content:'';
    position: absolute;
    top: 0.2rem;
    left: -0.4rem;
    width: 2rem;
    height: 2rem;
    display: block;
    background: lighten(saturate($primaryColor, 50%), 40%);
  }
}

.button {
  margin-left: 1rem;
}

.titleIcon {
  stroke: $primaryColor;
  font-size: 3rem;
  display: inline-block;
  margin-right: 1rem;
  transform: translateY(25%);
}
