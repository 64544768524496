$primaryColor: #462c6c;
$darkGrey: #676767;
$grey: #d8d8d8;
$lightGrey: #fafafa;
$errorRed: #c21515;
$successGreen: #41b841;

$zIndexBump: 10;
$zIndexHigh: 20;
$zIndexSuper: 30;
